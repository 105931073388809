import React from 'react'


export default () => (
    <div>
        <div style={{maxWidth: 900, padding: '40px 20px', margin: 'auto', fontSize: '1.1rem'}}>
            <p>Bienvenue de la part de notre équipe. Nous sommes une équipe de psychothérapeutes, psychologue, et psychiatres très expérimentés offrant des prises en charge de psychiatrie et psychothérapie intégrée (PPI) et des prises en charge en psychologie et en neuropsychologie (PN).</p>
            <p>Établis à Neuchâtel depuis 2003, nous nous spécialisons dans les psychothérapies à court et à long terme. Nous pratiquons différentes approches thérapeutiques comme la thérapie cognitivo-comportementale (TCC), l’hypnose, la programmation neurolinguistique (PNL), le « eye movements desensitization and reprocessing » (EMDR), l’approche psychodynamique, familiale, systémique, etc. Nous aidons nos patients à surmonter un éventail d’obstacles en commençant par les soucis quotidiens de la vie jusqu’aux conditions psychologiques complexes.</p>
            <p>Nos salles de thérapie sont situées à Lausanne - Les thérapies sues mentionnées ici que nous pratiquons sont reconnues par leur efficacité par la FMH (Fédération des médecins suisses) et nos prestations sont reconnues par l’assurance maladie de base(LAMal).</p>
        </div>
    </div>
)






