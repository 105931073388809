import React, {Component} from 'react'
import Carousel from '../components/Carousel/Carousel'
import Press from '../components/Press/Press'
import LausannePage from '../components/Contents/Home/LausannePage'
import HowItWorks from '../components/Contents/Home/HowIsWorks'
// import Definitions from '../components/Contents/Home/Definitions'
import Footer from '../components/Footer/FooterAds'
import HeroSection from '../components/HeroSection/Hero'
import MobileActions from '../components/MobileActions/MobileActions'
import Helmet from 'react-helmet'
import Layout from '../layouts'

export default class extends Component {
    state = {
        isMobile: false,
        windowWidth: null,
        isLoading: true,
    }

    componentDidMount() {
        this.setState({
            isMobile: window.innerWidth < 768,
            windowWidth: window.innerWidth,
            isLoading: false
        }, () => {
            window.addEventListener("resize", this.updateDeviceState)
        })
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDeviceState)
    }

    updateDeviceState = () => this.setState({ isMobile: window.innerWidth < 768, windowWidth: window.innerWidth})

    render() {

        return (
            <Layout location={this.props.location}>
                <Helmet>
                    <title>Psychologue et Psychiatre à Lausanne | Psy-Scan Institute</title>
                    <meta name="description" content="Groupe de psychologues et psychiatres à Lausanne offrant des prises en charge de psychiatrie et psychothérapie. Nous acceptons de nouveaux patients."/>
                </Helmet>
                <HeroSection {...this.state } {...this.props} city="Lausanne"/>
                <Carousel/>
                {/* <Journals/> */}
                <LausannePage />
                <HowItWorks/>
                {/* <Definitions isMobile={this.state.isMobile}/> */}
                <div style={{height: 1, width: '100%', backgroundColor: 'rgb(234, 234, 234)'}} />
                <Press/>
                
                <Footer/>
                {this.props.scrollPosition > 600
                    ? <MobileActions/>
                    : null
                }
            </Layout>
        )

    }
}


